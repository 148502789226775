import { FaCircle } from 'react-icons/fa';
import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {Link} from "react-router-dom";

function dateformatter(str: string) {
    const date = new Date(str);

    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ];

    const monthName = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0');
    const hours = (date.getUTCHours() % 12 || 12).toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';

    const result = {
        month: monthName,
        day,
        time: `${hours} ${ampm}`,
    };

    return result;
}

export function EventCard(props: { event: any }) {
    const date = dateformatter(props.event.date);
    return (
        <a
            href={`/event/${props.event.slug}`}
            className="max-w-[320px]  cursor-pointer bg-gray-50 rounded-2xl overflow-clip"
        >
            <div
                style={{
                    background: `url(${props.event.image})`,
                    backgroundSize: 'cover',
                }}
                className="h-48 rounded-2xl justify-items-start flex"
            >
                <div className="p-2 bg-white font-extrabold flex h-7 gap-1 rounded-xl flex-row justify-center items-center ml-2 mt-2 text-xs">
                    <FaCircle size={15} className="text-Maincolor" />
                    {props.event.location}
                </div>
            </div>
            <div className="flex h-[10rem] flex-row">
                <div className="flex p-2 w-32 flex-col items-center justify-evenly">
                    <p className="text-[15px] font-semibold">{date.month}</p>
                    <p className="text-[30px] font-extrabold header-font">{date.day}</p>
                    <p className="text-[13px] font-extralight text-Maincolor">
                        {date.time}
                    </p>
                </div>
                <div className="flex flex-col ">
                    <div className="p-2 flex flex-col justify-between gap-3">
                        <p className="text-[19px] font-extrabold">
                            {props.event.title}
                        </p>

                        <p className="overflow-ellipsis font-light line-clamp-3 text-sm">
                            {props.event.description}
                        </p>
                    </div>
                </div>
            </div>
        </a>
    );
}

export function EventCardRow(props: { event: any }) {
    const date = dateformatter(props.event.date);
    return (
        <div className="flex cursor-pointer capitalize flex-row justify-between border-b border-black">
            <div className="flex w-1/5 flex-col p-1">
                <p className="font-bold text-xl">{date.month}, {date.day}th</p>
                <p>{date.time}</p>
            </div>

            <div className="flex w-1/5 flex-col p-1 max-w-[250px]">
                <p className="font-bold text-xl">
                    {props.event.title}
                </p>
            </div>

            <div className="flex w-1/5 flex-col p-1">
                <p className="font-normal text-xl">{props.event.location}</p>
                <p>{props.event.location}, {props.event.country}</p>
            </div>

            <div className="flex w-1/5 justify-center items-center flex-col p-1">
                <Link to={`/event/${props.event.slug}`} className="text-xl hover:underline transition-opacity flex items-center gap-2 flex-row">
                    More Details <ArrowRightIcon className="w-5" />
                </Link>
            </div>
        </div>
    );
}

export function EventLoader() {
    return (
        <div className="w-64 h-64 max-w-[320px] animate-pulse cursor-pointer bg-gray-50 p-2 rounded-2xl overflow-clip"></div>
    );
}

export function EmailBox() {
    return (
        <div className="w-full max-w-[1200px] flex justify-center mt-10">
                <div
                    style={{
                        background: `url('https://wallpapercave.com/wp/wp2848024.jpg')`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                    }}
                    className="w-11/12 md:w-full rounded-3xl overflow-hidden bg-black"
                >
                    <div className="dark-bg backdrop-blur-sm flex justify-between gap-10 flex-col items-start p-4 md:py-4 md:px-20">
                        <div className="flex flex-col">
                            <p className="text-[40px] lg:text-[50px] font-extrabold text-white">
                                GET UPDATES FOR
                            </p>
                            <p className="text-[40px] lg:text-[50px] font-extrabold text-white">
                                POPULAR EVENTS
                            </p>
                            <p className="text-[40px] lg:text-[50px] font-extrabold text-Maincolor/90">
                                EVERYDAY
                            </p>
                        </div>
                        <div className="p-2 bg-white rounded-2xl mb-5 justify-center items-center gap-2 left-[20%] flex flex-row w-full">
                            <input
                                className="w-10/12 bg-transparent p-3 outline-none rounded-full"
                                type="email"
                                name="email"
                                id="email"
                            />
                            <div className="w-32 text-sm bg-Maincolor text-white text-center rounded-2xl py-3 p-2">
                                <p>Explore Events</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
