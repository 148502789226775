import Footer from '@/components/Footer';
import Header from '@/components/Header';
import config from '@/config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {FaCircle, FaMusic, FaSearch} from 'react-icons/fa';
import {
    EventCard, EventCardRow,
    EventLoader,
} from '@/components/appComponents';
import {
    ArrowPathIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline';
import banner from '../../../src/Images/banner.png';
import playpng from '../../../src/Images/google.png';
import phone from '../../../src/Images/phone.png';
import {useGetEventLocationsQuery, useGetEventsQuery, useGetPastEventsQuery} from "@/App/Events/api";
import {useGetAllCategoriesQuery} from "@/App/categories/api";

function CategoryItem({ title,icon, value }: { title: string; icon: string, value: string }) {
    return (
        <a
            href={`/events-category/${value}`}
            className=""
        >
            <div className="flex overflow-clip h-14 border border-gray-300/90 bg-gray-200 w-full flex-row justify-start sm:gap-2 items-center">
                <div className="p-3 w-[50px] h-14 text-Maincolor text-xl flex justify-center items-center bg-[#F5FAF0]/50">
                    <i className={icon.trim()} aria-hidden="true"></i>
                </div>
                <div className="p-1 sm:p-3 py-6 capitalize text-sm">
                    {title}
                </div>
            </div>
        </a>
    );
}

export const Home = () => {
    const [date, setDate] = useState('');

    const [selected, setSelected] = useState('');
    const [selectedLoc, setSelectedLoc] = useState('');

    const [selectedPastLocation, setSelectedPastLocation] = useState('');
    const [selectedPastCategory, setSelectedPastCategory] = useState('');

    const { isLoading, data, isFetching } = useGetEventsQuery(
        { pageNumber: 1, pageSize: 10, from: date, to: date, categoryId: selected, location: selectedLoc },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const past = useGetPastEventsQuery(
        { pageNumber: 1, pageSize: 10, categoryId: selectedPastCategory, location: selectedPastLocation },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const handleDateChange = (dateString: string) => {
        setDate(dateString);
    };

    const categories = useGetAllCategoriesQuery({});
    const locations = useGetEventLocationsQuery({});

    return (
        <main className="Container bg-gray-100 flex flex-col justify-center items-center">
            <Header />

            {/* Grid events */}
            <div className="md:px-5 w-full mx-5 mt-10">
                <div className="flex justify-center flex-col items-center bg-Maincolor w-full rounded-[30px]">
                    <div className="md:m-10 w-full md:w-11/12 flex justify-center pt-10 rounded-[30px] bg-gray-100">
                        <div className="w-full mx-4 max-w-[1200px] ">
                            <div className="flex items-center flex-wrap gap-3 justify-between">
                                <div className="header-font font-extrabold flex flex-row gap-2 text-2xl md:text-3xl">
                                    UPCOMING
                                    <div className="header-font text-Maincolor">
                                        {' '}
                                        EVENTS{' '}
                                    </div>
                                </div>

                                <div className="flex flex-wrap gap-2 items-center">
                                    <div className="relative max-w-sm">
                                        <div
                                            title="Sort By"
                                            className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
                                        >
                                            <svg
                                                className="w-4 h-4 text-Maincolor"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                            </svg>
                                        </div>
                                        <input
                                            onChange={e =>
                                                handleDateChange(e.target.value)
                                            }
                                            type="date"
                                            value={date}
                                            className="bg-gray-50 outline-none active:outline-none border active:border-Maincolor border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Sort By date"
                                        />
                                    </div>
                                    <select onChange={(e) => setSelectedLoc(e.target.value)}
                                            className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                        <option value={''}>Location</option>
                                        {!locations.isLoading && locations.data ? locations.data.map((location, index) => (
                                            <option value={location} className={"capitalize"} key={index}>{location}</option>
                                        )) : <>loading</>}
                                    </select>
                                    <select onChange={(e) => setSelected(e.target.value)}
                                            className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                        <option value={''}>Category</option>
                                        {!isLoading && categories.data ? categories.data.map((category, index) => (
                                            <option value={category.id} key={index}>{category.name}</option>
                                        )) : <>loading</>}
                                    </select>
                                    {date != '' && (
                                        <div
                                            onClick={() => setDate('')}
                                            className="ml-5 p-1"
                                        >
                                            <ArrowPathIcon className="w-3"/>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="w-full">
                                <div
                                    className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  items-center lg:grid-cols-3 xl:grid-cols-3 w-full gap-5 justify-center min-h-[300px] my-10">
                                    {isFetching
                                        ? [1,1,1].map((_, index) => (
                                            <EventLoader key={index}/>
                                        ))
                                        : data?.list.length === 0 ? <div className={"w-full text-center col-span-3"}>No Events Available</div> : data?.list.map(
                                              (event: any, index: number) => (
                                                  <EventCard
                                                      key={index}
                                                      event={event}
                                                  />
                                              )
                                          )}
                                </div>
                                <div className="flex justify-center items-center">
                                    <a
                                        href={'/events'}
                                        className="py-2 px-10 text-Maincolor border border-Maincolor rounded-2xl my-4"
                                    >
                                        See All
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-11/12 max-w-[1200px] mb-4 flex flex-col px-5">
                        <div className="flex flex-wrap mb-5 justify-between">
                            <div
                                className="header-font font-extrabold  flex flex-row gap-2 text-2xl mt-10 md:mt-0 md:text-3xl">
                                PAST
                                <div className="header-font text-white"> EVENTS </div>
                            </div>

                            <div className="flex flex-wrap gap-2 items-center">
                                <select onChange={(e) => setSelectedPastLocation(e.target.value)}
                                        className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                    <option value={''}>Location</option>
                                    {!locations.isLoading && locations.data ? locations.data.map((location, index) => (
                                        <option value={location} className={"capitalize"}
                                                key={index}>{location}</option>
                                    )) : <>loading</>}
                                </select>
                                <select onChange={(e) => setSelectedPastCategory(e.target.value)}
                                        className="bg-gray-50 px-3 py-2 border border-gray-300 rounded-lg capitalize">
                                    <option value={''}>Category</option>
                                    {!isLoading && categories.data ? categories.data.map((category, index) => (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    )) : <>loading</>}
                                </select>
                                {date != '' && (
                                    <div
                                        onClick={() => setDate('')}
                                        className="ml-5 p-1"
                                    >
                                        <ArrowPathIcon className="w-3"/>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="w-full overflow-scroll scrollbar-hidden">
                            <div className="flex w-full min-w-[800px] flex-col gap-2 pb-10">
                                {past.isFetching
                                    ? [1,1,1].map((_, index) => (
                                        <div key={index} className={"w-full mb-4 animate-pulse h-24 bg-gray-100/20"}></div>
                                    ))
                                    : past.data?.list.length === 0 ? <div className={"w-full min-h-[300px] flex justify-center items-center text-white text-center"}>No Events Found.</div> : past.data?.list.map((event: any) => (
                                        <EventCardRow key={event.id} event={event}/>
                                    ))}
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <a
                                href={'/past-events'}
                                className="py-2 px-10 bg-gray-100 text-Maincolor border border-Maincolor rounded-2xl my-4"
                            >
                                See All
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Categories />

            <div className="flex w-full justify-center items-center my-10">
                <Slider />
            </div>
            <div className="w-[97%] mx-5 flex justify-center">
                <div
                    className="bg-gray-200 shadow-lg md:h-48 overflow-hidden p-5 relative px-10 rounded-2xl justify-between w-full flex flex-wrap items-center my-5">
                    <div className="text-[32px] flex flex-col gap-0 z-10 font-extrabold">
                        <p className="header-font text-Maincolor h-8">DOWNLOAD</p>
                        <p className="header-font">RGTICKETS APP</p>
                    </div>

                    <img
                        className=" absolute w-64 left-1/2 rotate-45 -top-20"
                        src={phone}
                        alt="phone image"
                    />

                    <a href="https://play.google.com/store/apps/details?id=com.ishema.rgp_mobile">
                        <img

                            src={playpng}
                            alt="google play button"
                            className="w-32 z-10"
                        />
                    </a>
                </div>
            </div>

            {/* Footer */}
            <Footer/>
        </main>
    );
};

function Categories() {
    const {isLoading, data} = useGetAllCategoriesQuery({});

    const arr = [1, 1, 1, 1, 1, 1, 1];

    return (
        <div className="w-full px-4 sm:px-8 flex justify-center items-center">
            <div className="w-11/12 pt-10 flex flex-col">
                <div className="font-bold my-4 flex flex-col sm:flex-row text-lg gap-1">
                    CHECK
                    <div className=" text-Maincolor">OUT TRENDING</div>
                    {' '}
                    CATEGORIES
                </div>
                <div
                    className=" w-full py-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 overflow-scroll scrollbar-hidden gap-2">
                    {isLoading
                        ? arr.map((_, index) => (
                            <div key={index} className="p-6 w-full bg-gray-200 shadow-md animate-pulse"></div>
                        ))
                        : data?.map((item, index) => (
                            <CategoryItem key={index} icon={item.icon} title={item.name} value={item.id}/>
                        ))}
                </div>
            </div>
        </div>
    );
}

function Slider() {
    const images = [
        'https://c4.wallpaperflare.com/wallpaper/991/747/819/people-wallpaper-preview.jpg',
        'https://c4.wallpaperflare.com/wallpaper/509/60/23/football-fans-wallpaper-preview.jpg',
        'https://img.freepik.com/free-photo/back-view-crowd-fans-watching-live-performance-music-concert-night-copy-space_637285-544.jpg',
    ];
    const [selected, setSelected] = useState(0);

    const [word, setWord] = useState('');
    const [loc, setLoc] = useState('');
    const navigate = useNavigate();

    return (
        <div className="flex w-11/12 flex-col item-center justify-center">
            <div className="w-full justify-center flex-row gap-4 flex items-center">
                <div className="w-[90%] relative">
                    <div
                        style={{
                            backgroundImage: `url('${images[selected]}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                        className="h-[200px] transition-all md:h-[400px] lg:h-[500px] w-full">
                    </div>

                    <div
                        onClick={() =>
                            setSelected(r =>
                                r === 0 ? images.length - 1 : r - 1
                            )
                        }
                        className="z-20 top-1/2 ml-5 absolute cursor-pointer rounded-full bg-Maincolor p-1"
                    >
                        <ChevronLeftIcon className="text-white w-5" />
                    </div>
                    <div
                        onClick={() =>
                            setSelected(r =>
                                r === images.length - 1 ? 0 : r + 1
                            )
                        }
                        className="z-20 top-1/2 right-0 mr-5 absolute cursor-pointer  rounded-full bg-Maincolor p-1"
                    >
                        {' '}
                        <ChevronRightIcon className="text-white w-5" />{' '}
                    </div>

                    <div className="absolute z-20 flex flex-row gap-1 bottom-0 left-1/2">
                        {images.map((_, index) => (
                            <FaCircle
                                key={index}
                                className={`${
                                    selected === index
                                        ? 'text-white'
                                        : 'text-white/40'
                                } w-2`}
                            />
                        ))}
                    </div>

                    <div className="absolute flex font-[800] flex-col md:gap-10 z-20 left-[10%] top-1/4">
                        <p className="header-font text-xl md:text-[50px] lg:text-[70px] text-white">
                            DISCOVER
                        </p>
                        <p className="header-font text-xl md:text-[50px] lg:text-[70px] text-white">
                            BEST EVENTS
                        </p>
                        <p className="header-font text-xl md:text-[50px] lg:text-[70px] text-[#DBF4B3]">
                            TODAY
                        </p>
                    </div>

                    <div className="w-full gradient-bg h-[200px] md:h-[400px] lg:h-[500px]  absolute top-0 left-0 "></div>

                    <div
                        className="p-2 hidden md:flex bg-white rounded-full z-20 absolute bottom-10 justify-center items-center gap-2 left-[20%] flex-row w-3/5">
                        <strong>Find</strong>
                        <input
                            className="w-1/3 p-3 bg-gray-100 outline-none rounded-full"
                            type="search"
                            placeholder="Ex: Concerts, Comedy"
                            name="search"
                            id="search"
                            onChange={e => setWord(e.target.value)}
                        />
                        <strong>Where</strong>
                        <input
                            className="w-1/3 p-3 bg-gray-100 outline-none rounded-full"
                            type="search"
                            placeholder="Your city"
                            name="search"
                            id="search"
                            onChange={e => setLoc(e.target.value)}
                        />
                        <div
                            onClick={() => navigate(`/events?search=${word}&location=${loc}`)}
                            className="bg-Maincolor cursor-pointer p-5 rounded-full"
                        >
                            <FaSearch className="text-white"/>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        background: `url('${banner}')`,
                        backgroundSize: 'cover',
                    }}
                    className="w-28 hidden md:block bg-Maincolor h-[200px] md:h-[400px] lg:h-[500px] "
                ></div>
            </div>

            <div
                className="p-2 m-4 md:hidden bg-white rounded-full justify-center items-center gap-2 flex flex-row w-11/12">
                <strong>Find</strong>
                <input
                    className="w-1/3 p-3 bg-gray-100 outline-none rounded-full"
                    type="search"
                    placeholder="Ex: Concerts, Comedy"
                    name="search"
                    id="search"
                    onChange={e => setWord(e.target.value)}
                />
                <strong>Where</strong>
                <input
                    className="w-1/3 p-3 bg-gray-100 outline-none rounded-full"
                    type="search"
                    placeholder="Your city"
                    name="search"
                    id="search"
                    onChange={e => setWord(e.target.value)}
                />
                <div
                    onClick={() => navigate(`/events?search=${word}`)}
                    className="bg-Maincolor cursor-pointer p-3 rounded-full"
                >
                    <FaSearch className="text-white" />
                </div>
            </div>
        </div>
    );
}
