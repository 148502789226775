import Footer from '@/components/Footer';
import Header from '@/components/Header';
import React, {useEffect, useRef} from 'react';
import {useGetSingleTicketQuery} from "@/App/Tickets/api";
import {useParams} from "react-router-dom";
import {FaDotCircle} from "react-icons/fa";
import formatDateToLongForm from "@/utils/DateFormattter";
import IconNotes from "@/components/Icon/IconNotes";
import {
    ArrowDownOnSquareIcon,
    BuildingOffice2Icon,
    CalendarDaysIcon,
    CurrencyDollarIcon,
    MapIcon,
    MapPinIcon, PhoneIcon, UserCircleIcon
} from "@heroicons/react/24/outline";
import IconCalendar from "@/components/Icon/IconCalendar";
import {CircularProgress} from "@mui/material";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default function SingleTicket() {

    const { id } = useParams();

    const { isFetching, data, isError } = useGetSingleTicketQuery({ id: id ?? '' });

    const divRef = useRef<HTMLDivElement | null>(null);

    const exportAsImage = async () => {
        if (divRef.current) {
            try {
                const canvas = await html2canvas(divRef.current, { useCORS: true });
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'exported-image.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Failed to export as image:', error);
            }
        }
    };


    return (
        <main className="Container bg-gray-100 flex justify-center flex-col items-center ">
            <Header/>
            <button onClick={() => exportAsImage()}
                    className="text-xl my-10 p-4 items-center bg-Maincolor text-white px-2 rounded-md flex flex-row gap-1 text-center border-b font-normal dark:text-white">
                Download
                <ArrowDownOnSquareIcon className={'w-5'}/>
            </button>
            {isError ? <div className={"h-[500px] w-11/12 flex justify-center items-center bg-white"}>
                No Ticket Found.
            </div> : isFetching ? <div className={"h-[500px] w-11/12 flex justify-center items-center "}>
                <CircularProgress
                    className="text-Maincolor"
                    size={24}
                    sx={{color: '#8cc541'}}
                />
            </div> : <div
                className="w-full gap-4 justify-start items-center md:items-start md:w-12/12 min-h-[600px] flex flex-col md:flex-col md:max-w-[1000px] ">

                <div
                    ref={divRef}
                    className="p-5 w-full bg-gray-100 gap-3 flex flex-col md:flex-row justify-center items-start">
                    <div className="w-full flex flex-col gap-3 md:w-[40%]">
                        <div className="flex items-start w-full gap-2 flex-col">
                            {data?.id && (
                                <img
                                    src={`https://quickchart.io/qr?text=${encodeURIComponent(
                                        data?.id
                                    )}`}
                                    className="w-full"
                                    alt={"image"}></img>
                            )}
                            <div className="py-2 w-full items-center flex flex-row justify-between">
                                {data?.scanned_at == null ? (
                                    <div className="flex w-full flex-row bg-white p-2 items-center rounded-md">
                                        <div
                                            className="w-3 h-3 flex justify-center items-center text-Maincolor bg-gray-100 shadow-sm rounded-xl">
                                            <FaDotCircle className="w-2"/>
                                        </div>
                                        <div className="px-3">
                                            <p className="text-gray-500 text-xs">
                                                Ticket Not
                                                Scanned
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex w-full flex-row bg-white p-2 items-center rounded-md">
                                        <div className="flex flex-row">
                                            <div
                                                className="w-3 h-3 flex justify-center items-center text-red-600 bg-gray-100 shadow-sm rounded-xl">
                                                <FaDotCircle className="w-2"/>
                                            </div>
                                            <div className="px-3">
                                                <p className="text-gray-500 text-xs">
                                                    Ticket
                                                    Scanned
                                                </p>
                                            </div>
                                        </div>

                                        <p className="text-gray-500 text-xs">
                                            {formatDateToLongForm(
                                                data?.scanned_at
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full bg-white p-5">
                            <div className="flex flex-row justify-between pb-3 border-b ">
                                <h3 className="text-xl text-centerfont-normal text-gray-600 dark:text-white">
                                    Ticket Details
                                </h3>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <IconNotes/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Ticket Type
                                    </p>
                                    <p>
                                        {
                                            data?.pricing.type
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <CurrencyDollarIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Price
                                    </p>
                                    <p>
                                        {data?.pricing.price && <>{data?.pricing.price.toLocaleString()} {data?.event?.currency}</>}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <IconCalendar/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Date Purchased
                                    </p>
                                    <p>
                                        {formatDateToLongForm(
                                            data?.created_at
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col gap-4">
                        <div className="w-full bg-white p-5">
                            <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                User Details
                            </h3>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <UserCircleIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Full Names
                                    </p>
                                    <p>
                                        {data?.names}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <PhoneIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Phone Number
                                    </p>
                                    <p>
                                        {data?.phoneNumber}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full bg-white p-5">
                            <h3 className="text-xl text-center border-b pb-3 font-normal text-gray-600 dark:text-white">
                                Event Details
                            </h3>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <CalendarDaysIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Start Date
                                    </p>
                                    <p>
                                        {formatDateToLongForm(
                                            data?.event?.date
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <MapIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Country
                                    </p>
                                    <p>
                                        {data?.event?.country}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <MapPinIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Location
                                    </p>
                                    <p>
                                        {data?.event?.location}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <BuildingOffice2Icon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Event Organizer
                                    </p>
                                    <p>
                                        {data?.event?.organiser}
                                    </p>
                                </div>
                            </div>
                            <div className="py-4 px-2 items-center flex flex-row">
                                <div className="w-10 p-2 text-Maincolor h-10 bg-gray-100 shadow-sm rounded-xl">
                                    <PhoneIcon/>
                                </div>
                                <div className="px-3">
                                    <p className="text-gray-500 text-sm">
                                        Contacts
                                    </p>
                                    <p>
                                        {
                                            data?.event?.phoneNumber
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {/* Footer */}
            <Footer/>
        </main>
    );
}
